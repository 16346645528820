<template>
  <div style="background-color: #171726;min-height: 100vh;overflow: scroll;">
    <div class="title_top">

      <nav-bar :icon="'img/backw.png'" :textColor="'#ffffff'" :backColor="'#171726'" :rightbg="'img/search.png'" />

      <div class="display_flex justify-content_flex-justify margin-top padding-lr-sm">
        <div class="mynav display_flex flex-direction_column align-items_center"
          :style="{ backgroundImage: 'url(img/myBg1.png)' }" @click="typeChange(1)">
          <span class="margin-top-xs">{{ invite_num }}</span>
          <p>邀请人数</p>

        </div>
        <div class="mynav display_flex flex-direction_column align-items_center margin-lr-sm"
          :style="{ backgroundImage: 'url(img/myBg2.png)' }" @click="typeChange(2)">
          <span class="margin-top-xs">{{ effective_num }}</span>
          <p>下级累计充值</p>

        </div>
        <div class="mynav display_flex flex-direction_column align-items_center"
          :style="{ backgroundImage: 'url(img/myBg3.png)' }">
          <div class="display_flex align-items_center margin-top">
            <span class="margin-left-xs" style="margin: 0;">{{ send_money }}</span>
            <img src="img/bi.png" alt="">
          </div>
          <p>下级累计赠送</p>

        </div>
      </div>

      <div class="display_flex justify-content_flex-justify align-items_center margin-bottom-xl padding-lr-lg margin-top"
        style="position:relative;">
        <p class="my_title">排序规则</p>
        <div class="my_sort display_flex align-items_center" @click="sortClick">
          <p class="margin-right-xs">{{ sortData[sortType].name }}</p>
          <img src="img/sort.png" alt="">
        </div>
        <div class="sort_box padding-tb-sm" v-if="sortDisplay">
          <p :class="{ 'sort_cur': sortData[sortType].type == index }" v-for="(item, index) in sortData" :key="index"
            @click="sortChange(index)">{{ item.name }}</p>
        </div>
      </div>

      <div class="padding-lr-sm">
        <!--  @click="goDetail(item)" -->
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="fetchData">
          <div class="myItem margin-bottom-sm" style="background-image: url('img/item_bg.png');"
            v-for="(item, index) in listData" :key="index">

            <div class="my_itemTop display_flex justify-content_flex-justify align-items_center padding-lr-sm">
              <div class="display_flex align-items_flex-end top">
                <div style="width: 50%;display: flex;align-items: center;">
                  <div class="my-order margin-right-sm"
                    style="width: 30px;height: 30px;border-radius: 50%;display: flex;justify-content: center;align-items: center;background-color:rgba(255, 255, 255, .1)">
                    <img v-if="index < 3" class="my-num" :src="'img/' + (index + 1) + '.png'" alt="">
                    <span style="color:#ffffff" v-else>{{ index + 1 }}</span>
                  </div>
                  <div>
                    <p style="color:#ffffff;font-size: 16px;display: flex;align-items: center;">{{ item.nickname }}<img class="renz" v-if="item.is_auth == 1" src="img/renz.png" alt=""></p>
                    <div class="my_id" style="color:#999999">ID:{{ item.uid }}</div>
                  </div>
                </div>
                <!-- <div class="margin-top-sm top-right">{{ item.created_at }}</div> -->
              </div>
              <div class="last_gift" v-if="item.is_auth == 3">
                <img src="img/renz.png" alt="">
              </div>
            </div>
            <div style="background-color: rgba(255, 255, 255, .1);height: 1px;width: 95%;margin: 0 auto;"></div>
            <div class="my_content display_flex justify-content_flex-justify align-items_center">
              <div>
                <div class="font_size_30">{{item.invite_sum}}</div>
                <div class="font_size_26">邀请人数</div>
              </div>
              <div>
                <div class="font_size_30">{{item.total_recharge}}</div>
                <div class="font_size_26">累计充值</div>
              </div>
              <div>
                <div class="font_size_30">{{item.total_value}}<img class="gold" src="img/bi.png" alt=""></div>
                <div class="font_size_26">累计赠送</div>
              </div>
            </div>
  

          </div>
        </van-list>
        <div class="noData margin-top-xl" v-if="noData">
          <img src="img/noData.png" alt="">
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import "@/assets/css/base.css"
import "@/assets/css/index.css"
import { myInviteDetail } from "@/api/api";
import NavBar from '@/components/navBar.vue';
export default {
  name: 'myInvite',
  components: {
    NavBar
  },
  data() {
    return {
      sortData: [
        {
          type: 0,
          name: '邀请人数优先'
        },
        // {
        //     type:1,
        //     name:'有效认证优先'
        // },
        {
          type: 2,
          name: '注册时间优先'
        }
      ],
      sortType: 0,
      sortDisplay: false,
      invite_num: '',
      effective_num: '',
      send_money: '',
      listData: [],
      noData: false,
      type: 1,
      loading: false,
      finished: false,
      page: 1,
      row: 20,
    };
  },
  created() {
    this.invite_num = this.$route.query.invite_num
    this.effective_num = this.$route.query.effective_num
    this.send_money = this.$route.query.send_money * 10
  },
  methods: {
    fetchData() {
      const Params = {
        sort: this.sortType,
        type: this.type,
        page: this.page,
        rows: this.row
      }
      myInviteDetail(Params).then(response => {
        this.listData = this.listData.concat(response.data)
        this.loading = false;
        if (response.data.length < this.row) {
          this.finished = true;
        }
        if (this.page == 1 && response.data.length == 0) {
          this.noData = true
        } else {
          this.noData = false
        }
        this.page = this.page + 1
        this.sortDisplay = false
      },
        error => {
          this.loading = false;
          this.finished = true;
          this.$toast.fail(error)
        }).catch((error) => {
          this.loading = false;
          this.finished = true;
          this.$toast.fail(error)
        })
    },
    typeChange(e) {
      this.type = e
      this.loading = true;
      this.finished = false;
      this.listData = [];
      this.page = 1
      this.fetchData()
    },
    sortClick() {
      this.sortDisplay = !this.sortDisplay
    },
    sortChange(e) {
      this.sortType = e
      this.loading = true;
      this.finished = false;
      this.listData = [];
      this.page = 1
      this.fetchData()
      this.sortDisplay = false
    },
    goDetail(item) {
      this.$router.push({ path: '/lastInvite', query: { id: item.send_user_id, invite_sum: item.invite_sum, s_send_sum: item.s_send_sum, nickname: item.nickname, effective_num: item.effective_num } })
    }


  },
  watch: {
    '$route': "fetchData"
  }
};
</script>

<style>
/* html,
body {
  background-color: #171726;
  height: 100%;
  overflow: scroll;
} */
</style>